import React, { useEffect, useState } from "react";
import "../style/Funnel.css";
import FunnelGraph from "funnel-graph-js";
import { useQuery, useLazyQuery } from "@apollo/client";
import { GET_USER_ACCOUNTS, GET_GROUPED_METRICS } from "../modules/queries.js";
import { gql } from "@apollo/client";

export default function Funnels() {
  const [reachData, setReachData] = useState([]);
  const [websiteTrafficData, setWebsiteTrafficData] = useState([]);
  const [salesData, setSalesData] = useState([]);

  const conditions = [
    { platform: "instagram", accountId: "17841400009052327", metric: "reach" },
    {
      platform: "tiktok",
      accountId: "-000okrOHusRnq5rWAalSoc1wkNjJPmln4iL",
      metric: "video_views",
    },
    {
      platform: "ga4",
      accountId: "283284385",
      metric: "visitors",
    },
    {
      platform: "ga4",
      accountId: "283284385",
      metric: "sales",
    },
  ];

  // Define the GET_GROUPED_METRICS query directly within the component
  const GET_GROUPED_METRICS = gql`
    query GetGroupedMetrics($conditions: [MetricCondition]!) {
      groupedMetrics(conditions: $conditions) {
        accountId
        platform
        metric
        username
        data {
          nonBreakdown {
            date
            value
          }
          breakdowns {
            key
            values {
              date
              value
            }
          }
        }
      }
    }
  `;

  const {
    data: groupedMetricsData,
    loading: groupedMetricsLoading,
    error: groupedMetricsError,
  } = useQuery(GET_GROUPED_METRICS, {
    variables: { conditions },
  });
  const metricsData = groupedMetricsData?.groupedMetrics;

  useEffect(() => {
    if (metricsData) {
      const startDate = new Date("2024-07-01").getTime();
      const endDate = new Date("2024-07-31").getTime();

      let reachAggregate = 0;
      let trafficAggregate = 0;
      let salesAggregate = 0;

      metricsData.forEach((metric) => {
        if (metric.metric === "reach" || metric.metric === "video_views") {
          // Aggregate for reach and video_views
          if (metric.data.breakdowns) {
            metric.data.breakdowns.forEach(({ key, values }) => {
              if (!["AD", "DEFAULT_DO_NOT_USE"].includes(key)) {
                values.forEach(({ date, value }) => {
                  const dateMillis = new Date(date).getTime();
                  if (dateMillis >= startDate && dateMillis <= endDate) {
                    reachAggregate += value;
                  }
                });
              }
            });
          }
        } else if (metric.metric === "visitors") {
          // Aggregate for visitors
          metric.data.nonBreakdown.forEach(({ date, value }) => {
            const dateMillis = new Date(date).getTime();
            if (dateMillis >= startDate && dateMillis <= endDate) {
              trafficAggregate += value;
            }
          });
        } else if (metric.metric === "sales") {
          // Aggregate for sales
          metric.data.nonBreakdown.forEach(({ date, value }) => {
            const dateMillis = new Date(date).getTime();
            if (dateMillis >= startDate && dateMillis <= endDate) {
              salesAggregate += value;
            }
          });
          salesAggregate = salesAggregate / 100;
        }
      });

      // Update states
      setReachData([reachAggregate]);
      setWebsiteTrafficData([trafficAggregate]);
      setSalesData([salesAggregate]);
    }
  }, [metricsData]);

  useEffect(() => {
    if (
      reachData.length > 0 &&
      websiteTrafficData.length > 0 &&
      salesData.length > 0
    ) {
      const sqrtTransform = (values) => values.map((value) => Math.sqrt(value));

      const visualInteractionsValues = [
        sqrtTransform([2500, 20300, 1200]), //Engagement
        sqrtTransform([738, 113, 0]), // Shop interactions
        sqrtTransform([78, 14, 0]), // Subscription
      ];

      const realInteractionsValues = [
        [2500, 20300, 1200], // Engagement
        [738, 113, 0], // Shop interactions
        [78, 14, 0], // Subscription
      ];

      const visualSalesValues = [
        sqrtTransform([1593, 399, 1626, 11365, 92]), // Reach
        sqrtTransform([62, 14, 47, 16, 0]), // newsletters
        sqrtTransform([40, 30, 35, 6, 0]), // Sales (4 * 2500 = 10000)
      ];

      const realSalesValues = [
        // direct, google, facebook, instagram, tiktok
        [15938, 3999, 16263, 113652, 924], // Reach
        [32, 7, 47, 16, 0], // newsletters
        [114, 68, 101, 12, 0], // Sales (4 * 2500 = 10000)
      ];

      //subscriers 1 ig, 3 google, 5 direct, paid 0
      const data = {
        labels: ["Reach", "Newsletter", "Sales"],
        subLabels: ["Direct", "Google", "Facebook", "Instagram", "TikTok"],
        colors: [
          ["#D6D6D6", "#D6D6D6", "#D6D6D6"],
          ["#699D6B", "#699D6B", "#699D6B"],
          ["#1877F2", "#1877F2", "#1877F2"],
          ["#CA6DB1", "#CA6DB1", "#CA6DB1"],
          ["#232323", "#232323", "#232323"],
        ],
        values: visualSalesValues,
      };

      const options = {
        container: ".funnel-container",
        gradientDirection: "horizontal",
        data: data,
        displayPercent: true,
        direction: "horizontal",
        width: 1000,
        height: 350,
        scales: {
          yAxes: [
            {
              type: "linear",
              ticks: {
                min: 0,
                max: 10000,
                stepSize: 1000,
              },
            },
          ],
        },
      };

      try {
        const graph = new FunnelGraph(options);
        graph.draw();
      } catch (e) {
        console.error("There was an issue initializing the funnel graph:", e);
      }
    }
  }, [reachData, websiteTrafficData, salesData]);

  return (
    <div className="dashboard">
      <p>🌱 Organic Funnel</p>
      <div
        className="funnel-container"
        style={{ width: "100%", height: "400px" }}
      />
    </div>
  );
}
