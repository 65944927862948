import React, { useState, useEffect, useRef } from "react";
import "../style/Competitors.css";
import api from "../modules/Api";
import FollowersCompairson from "../modules/FollowersCompairson.js";
import MediaTypeComparison from "../modules/MediaTypeCompairson.js.js";
import EngagementCompairson from "../modules/EngagementCompairson.js";
import PerPostEngagement from "../modules/PerPostEngagement.js";
import trackEvent from "../modules/SimpleTracker.js";
import Calculator from "../modules/Calculator.js";
import GoalCalculator from "../modules/GoalCalculator.js";
import Footer from "../modules/Footer.js";

export default function Competitors() {
  const toMetricRef = useRef();
  const [requestAccessEmail, setRequestAccessEmail] = useState();
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState();
  const [email, setEmail] = useState();
  const [insight, setInsight] = useState();
  const [serverError, setServerError] = useState("");
  const [chatMessage, setChatMessage] = useState();
  const [loadingChat, setLoadingChat] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false); // Added state for modal visibility
  const [testLogin, setTestLogin] = useState({ email: "", pwd: "" });
  const [competitors, setCompetitors] = useState([{ id: 1, value: "" }]);

  const testCall = async () => {
    try {
      const response = await api.post("/gh/metrics", {
        token: "growupthehouse",
        email: "test@growhouse.co",
        account: "transpa.rent",
        platform: "instagram",
        metric: "followers",
        start: "2023-05-31",
        end: "2023-06-30",
      });
      if (response.status !== 200) {
        console.log("Response not 200");
      }
      console.log(response.data);
    } catch (error) {
      console.log("error in testCall", error.message);
    }
  };
  // testCall();
  useEffect(() => {
    if (!isLoading && insight) {
      toMetricRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isLoading, insight]);

  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position and determine if it's a significant scroll
      // For example, reaching the bottom of the page
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        trackEvent("reached-bottom");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const addCompetitor = () => {
    trackEvent("comp-added");

    // Check if the current number of competitors is less than 10
    if (competitors.length < 5) {
      const newCompetitorId = competitors.length + 1;
      setCompetitors([...competitors, { id: newCompetitorId, value: "" }]);
    } else {
      // Optionally, you can alert the user that the limit has been reached
      alert("You can only add up to 5 competitors.");
    }
  };

  const removeCompetitor = (id) => {
    trackEvent("comp-removed");

    // Remove competitor from competitors array
    const newCompetitors = competitors.filter(
      (competitor) => competitor.id !== id
    );
    setCompetitors(newCompetitors);

    // Remove competitor from formData
    const newFormData = { ...formData };
    delete newFormData[`ig_comp_${id}`];
    setFormData(newFormData);
  };

  const handleFormChange = (e) => {
    const { value, name } = e.target;

    setFormData((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;

    setEmail(value);
  };

  const handleRequestAccess = (e) => {
    const { value } = e.target;

    setRequestAccessEmail(value);
  };

  const handleTestLogin = (e) => {
    const { name, value } = e.target;
    setTestLogin((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (isLoading) {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }

    // Cleanup to remove the cursor style when the component unmounts
    return () => {
      document.body.style.cursor = "default";
    };
  }, [isLoading]);
  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation

    if (!email) {
      errors.email = "Email is required.";
      setFormErrors(errors);

      return;
    } else if (!emailRegex.test(email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!formData?.ig_main || formData?.ig_main === "") {
      errors.ig_main = "Main Instagram handle is required.";
    }

    for (let i = 1; i <= 10; i++) {
      const igHandleKey = `ig_comp_${i}`;
      const igHandle = formData[igHandleKey];

      // Check if the field exists in formData and if it's an empty string after trimming
      if (igHandle !== undefined && igHandle.trim() === "") {
        errors[igHandleKey] = `Instagram handle cannot be empty.`;
      }
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const easeOutQuad = (t) => t * (2 - t);

  const getInsights = async () => {
    if (!validateForm()) {
      // If the form is not valid, stop the function here
      return;
    }
    setIsLoading(true);

    const accountCount = Object.keys(formData).length;
    const totalLoadTime = 2 * accountCount; // Total time in seconds

    let progress = 0;
    const startTime = Date.now();

    const interval = setInterval(() => {
      const elapsedTime = (Date.now() - startTime) / 1000; // Time in seconds
      const normalizedTime = elapsedTime / totalLoadTime;
      progress = easeOutQuad(normalizedTime) * 100;

      setLoadingProgress(progress);
      if (progress >= 100 || elapsedTime >= totalLoadTime)
        clearInterval(interval);
    }, 100); // Update every 0.1 second

    try {
      const response = await api.post("/open/business/discovery", {
        formData,
        email,
      });
      if (response.status !== 200) {
        console.log("Response not 200");
      }
      setInsight(response.data);
      // getAiInsights(response.data);
    } catch (error) {
      setInsight();
      console.log(error.response);
      if (error.response?.status === 404) {
        const username = error.response.data.username;
        const message = `"${username}" ${error.response.data.msg}`;

        for (const key in formData) {
          if (formData[key] === username) {
            // Update your form error state to show error next to this field
            setFormErrors((prevErrors) => ({ ...prevErrors, [key]: message }));
            break;
          }
        }
        return;
      } else if (error.response?.status === 403) {
        setServerError(error.response.data.msg);
      } else {
        setServerError(`oops, something went wrong: ${"5xx"}`);
      }
    } finally {
      setIsLoading(false);
      clearInterval(interval); // Ensure clearing interval
      setLoadingProgress(0); // Reset progress
    }
  };

  const getAiInsights = async (instagramData) => {
    try {
      console.log("Calling chat api");
      setLoadingChat(true);
      const response = await api.post("/open/chat", instagramData);
      if (response.status !== 200) {
        console.log("Response not 200 from chat");
        return;
      }
      console.log(response.data.message);

      setChatMessage(response.data.message);
      setLoadingChat(false);
    } catch (error) {
      console.log("error in getAiInsights", error.message);
    }
  };

  const loginFacebookUser = async () => {
    try {
      const response = await api.post("/login/oauth/url");
      if (response.status !== 200) {
        console.error("Error in loginUser", response.status);
        return;
      }
      window.location.href = response.data;
    } catch (error) {
      console.error("Error in loginUser", error.message);
    }
  };
  console.log(chatMessage);

  const requestAccess = async () => {
    if (!requestAccessEmail) {
      return;
    }
    try {
      const response = await api.post("email/send/request/access", {
        email: requestAccessEmail,
      });
      if (response.status !== 200) {
        console.error("Error in requestAccess", response.status);
        return;
      }
      window.location.href = "/";
    } catch (error) {
      console.error("Error in requestAccess", error.message);
    }
  };

  console.log("competitors:", insight);
  // const loginTestUser = async () => {
  //   try {
  //     const response = await api.post("/login/test", testLogin);
  //     if (response.status !== 200) {
  //       console.error("Error in loginTestUser", response.status);
  //       return;
  //     }
  //     window.location.href = "/test-dash";
  //     console.log(response.data);
  //   } catch (error) {
  //     console.log("Error in loginTestUser", error.message);
  //   }
  // };
  // const loginGoogleUser = async () => {
  //   try {
  //     const response = await api.post("/login/google/url");
  //     if (response.status !== 200) {
  //       console.error("Error in loginUser", response.status);
  //       return;
  //     }
  //     window.location.href = response.data;
  //   } catch (error) {
  //     console.error("Error in loginUser", error.message);
  //   }
  // };
  return (
    <>
      <div className="header">
        <div
          className="GH__sigil"
          onClick={() => {
            window.location = "https://growhouse.co";
          }}
        >
          gh.
        </div>
        <button
          id="login--btn"
          className="login--btn"
          onClick={() => setShowLoginModal(true)}
        >
          Log in
        </button>
      </div>
      <div className="competitors">
        <div className="analysis__form">
          <div className="hero">
            <h1>ORGANIC GROWTH</h1>
            <h4>
              Compare your account with your
              <span className="underline"> competitors</span>.
            </h4>
          </div>
          <div className="account__form">
            <h2>You</h2>
            {formErrors.email && (
              <small className="error">{formErrors.email}</small>
            )}
            <input
              type="email"
              name="email"
              onChange={handleEmailChange}
              onClick={(e) => {
                e.preventDefault();
                trackEvent("email-input-clicked");
              }}
              placeholder="Email.."
            />

            {formErrors.ig_main && (
              <small className="error">{formErrors.ig_main}</small>
            )}
            <input
              value={formData?.["ig_main"]
                ?.toLowerCase()
                .replace(/\s+/g, "")
                .replace(/@/g, "")}
              name="ig_main"
              placeholder="@.."
              onChange={handleFormChange}
            />
          </div>

          <div className="competitor__form">
            <h2>competitors</h2>

            {competitors.map((competitor, index) => {
              return (
                <>
                  {formErrors[`ig_comp_${competitor.id}`] && (
                    <small className="error">
                      {formErrors[`ig_comp_${competitor.id}`]}
                    </small>
                  )}
                  <div className="competitors__div" key={competitor.id}>
                    <input
                      name={`ig_comp_${competitor.id}`}
                      value={formData?.[`ig_comp_${competitor.id}`]
                        ?.toLowerCase()
                        .replace(/\s+/g, "")
                        .replace(/@/g, "")}
                      placeholder="@.."
                      onChange={handleFormChange}
                    />
                    {index > 0 && (
                      <img
                        key={index}
                        onClick={() => removeCompetitor(competitor.id)}
                        src="https://i.postimg.cc/dLM8QBbC/white-02.png"
                        alt="minus-btn"
                      />
                    )}
                  </div>
                </>
              );
            })}
            {competitors.length < 10 && (
              <div onClick={addCompetitor} className="add__button">
                <img
                  src="https://i.postimg.cc/CzjbR80Q/white-03.png"
                  alt="plus"
                />
                <p>add more competitors</p>
              </div>
            )}
          </div>
          {serverError && <small className="error">{serverError}</small>}
          <div className="get__button">
            <p>
              We'll remember you in case we find valuable insights to share.
            </p>
            <button onClick={getInsights} disabled={isLoading}>
              {isLoading ? (
                <div
                  className="loading-bar"
                  style={{ width: `${loadingProgress}%` }}
                ></div>
              ) : (
                "LOAD DATA"
              )}
            </button>
          </div>
        </div>
        <div ref={toMetricRef}>
          {insight && (
            <>
              <Calculator competitors={insight} />
              <GoalCalculator
                mainAccount={insight.find((comp) => comp.isMainAccount)}
              />

              <h2>Calculated on following data:</h2>
              <div className="data__composer">
                <FollowersCompairson competitors={insight} />
                <MediaTypeComparison competitors={insight} />
                <EngagementCompairson competitors={insight} />
                <PerPostEngagement competitors={insight} />
              </div>
              {insight && loadingChat ? (
                <div className="loading__container">
                  <div className="loading-spinner">
                    <img
                      className="loading__img"
                      src="/gh-logo.png"
                      alt="Growhouse logo"
                    />

                    <img
                      className="loading-spinner__img"
                      src="/openai-logo.png" // Replace with the actual path
                      alt="Loading..."
                    />
                  </div>
                  <p>Please wait, your AI goals will appear here soon...</p>
                </div>
              ) : (
                <>
                  <div className="chat__div">
                    <div className="chat__img__container">
                      <img
                        className="chat__powered"
                        src="/powered-by-openai.svg"
                        alt="Powered by OpenAI"
                      />
                    </div>
                    <h1>AI - HOW TO GROW</h1>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: chatMessage?.content.replace(/\n/g, "<br/>"),
                      }}
                    ></p>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {insight && (
        <Footer email={email?.email} insights={insight} formData={formData} />
      )}
      {showLoginModal && (
        <div className="login-modal" onClick={() => setShowLoginModal(false)}>
          <div
            className="login-modal-content"
            onClick={(event) => event.stopPropagation()}
          >
            <div className="login-modal-header">
              gh.
              <h2>Welcome to Growhouse</h2>
            </div>
            <input
              className="login-modal-input"
              type="email"
              placeholder="Email.."
              name="email"
              value={testLogin?.email}
              onChange={handleTestLogin}
            />
            <input
              className="login-modal-input"
              type="password"
              placeholder="Password.."
              name="pwd"
              value={testLogin?.pwd}
              onChange={handleTestLogin}
            />
            <button
              disabled={true}
              className="login-modal-action-button"
              // onClick={loginTestUser}
            >
              Log in
            </button>
            <div className="login-modal-separator">
              <span className="login-modal-separator-text">OR</span>
            </div>
            <button
              className="login-modal-secondary-action-button login-facebook"
              onClick={loginFacebookUser}
            >
              <img
                src="./facebook_logo.png"
                alt="Facebook"
                className="login-button-logo"
              />
              Continue with Facebook
            </button>
            {/* <button
              className="login-modal-secondary-action-button login-google"
              onClick={loginGoogleUser}
            >
              <img
                src="./google_logo.png"
                alt="Google"
                className="login-button-logo"
              />
              Continue with Google
            </button> */}
            <div className="login-modal-terms">
              By signing up, you agree to our{" "}
              <a href="/terms">Terms of Service</a> and{" "}
              <a href="/privacy">Privacy Policy</a>.
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}
