import React from "react";
import "../style/Dashboard.css";
import { useCookie } from "../modules/CookieContext.js";

export default function TestDashboard() {
  const { name } = useCookie();

  // Hardcoded Instagram user data
  const userData = {
    id: "17841405793187218",
    username: "testaccount",
    account_type: "BUSINESS",
    media_count: 1822,
    followers_count: "128K",
    follows_count: 2197,
    biography:
      "Test Account - Test (brand) - The Test users text for inspiration!\nTimeless Performance ⭐ Sustainable Quality - Fast shipping to 43 countries...",
    profile_picture_url:
      "https://e7.pngegg.com/pngimages/753/432/png-clipart-user-profile-2018-in-sight-user-conference-expo-business-default-business-angle-service-thumbnail.png",
    website: "https://www.test.com",
  };

  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <h2>Welcome {name.split(" ")[0]}!</h2>
      </div>

      <div className="profile" style={{ color: "black" }}>
        <div className="profile__header">
          {/* Profile Picture */}
          <img
            src={userData.profile_picture_url}
            alt="Profile"
            className="profile__picture"
            style={{ borderRadius: "50%", height: "150px" }}
          />

          {/* Profile Stats */}
          <div
            className="profile__stats"
            style={{ display: "flex", gap: "2rem" }}
          >
            <div className="profile__stat">
              <strong>{userData.media_count}</strong> posts
            </div>
            <div className="profile__stat">
              <strong>{userData.followers_count}</strong> followers
            </div>
            <div className="profile__stat">
              <strong>{userData.follows_count}</strong> following
            </div>
          </div>
        </div>

        {/* Username and Biography */}
        <div className="profile__details">
          <h3 className="profile__username">
            {userData.username} <span className="verified">✔️</span>
          </h3>
          <p className="profile__bio">
            {userData.biography.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p>

          {/* Website */}
          <p className="profile__website">
            <a
              href={userData.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              {userData.website}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
