import React, { useState, useEffect, useRef } from "react";
import { LuzmoDashboardComponent } from "@luzmo/react-embed";
import api from "../modules/Api.js";
import "../style/App.css";

const Luzmo = () => {
  const [filterName, setFilterName] = useState("dometic");
  const [authData, setAuthData] = useState({ authKey: "", authToken: "" });
  const ref = useRef(null);

  useEffect(() => {
    const fetchAuthData = async () => {
      try {
        // Make a GET request to the backend endpoint to get the authorization key and token
        const response = await api.get("/luzmo/dashboard", {
          params: { filterName },
        });

        // Assuming the response structure matches what we set up in the backend
        setAuthData({
          authKey: response.data.authKey,
          authToken: response.data.authToken,
        });
      } catch (error) {
        console.error("Error fetching authorization data:", error.message);
      }
    };

    fetchAuthData();
  }, [filterName]);

  if (!authData.authKey || !authData.authToken) {
    return <div>Loading dashboard...</div>; // Show a loading message while waiting for the auth data
  }

  return (
    <div className="Luzmo">
      <select
        className="luzmo-filter-select"
        onChange={(e) => setFilterName(e.target.value)}
        value={filterName}
      >
        <option value="dometic">Dometic</option>
        <option value="stellarequipment">Stellarequipment</option>
        <option value="pocsports">POC</option>
      </select>
      <LuzmoDashboardComponent
        ref={ref}
        authKey={authData.authKey} // Use fetched authKey
        authToken={authData.authToken} // Use fetched authToken
        dashboardId="bb98b656-fc82-4b72-835c-562f626f5da5" // Replace with the actual Dashboard ID (UUID) if available
        appServer="https://app.luzmo.com/"
        switchScreenModeOnResize={false}
        loaderSpinnerColor="rgb(0, 81, 126)"
        loaderSpinnerBackground="rgb(236 248 255)"
        itemsRendered={(e) => console.log("itemsRendered", e)}
        css={`
          .ng-tns-c2267794089-0 {
            display: none !important;
          }
        `}
      />
    </div>
  );
};

export default Luzmo;
